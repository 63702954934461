html,
body {
  height: 100%;
  overflow: hidden;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}


/**
 * Здесь и далее после перехода на antd v5 приходится костылить часть стилей, хотя может местами есль решение получше 
*/

p,
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  margin: 0px;
  line-height: 1.4 !important;
}

/* Возвращаем нормальное межстрочное расстояние */
.ant-typography {
  line-height: normal !important;
}

.ant-page-header {
  padding: 16px 24px;
}

/* Иначе на цветном фоне disabled кнопки становятся прозрачными */
.ant-btn:disabled {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

/* Лечим модалки */
.ant-modal-header {
  padding: 16px 24px !important;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 0 !important;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-close {
  margin: -1px -1px 0 0;
}

.ant-modal-title {
  font-weight: 500 !important;
}

.ant-modal-title .ant-typography {
  font-size: 16px;
}

.ant-modal-body {
  padding: 24px !important;
}

.ant-modal-footer {
  border-top: 1px solid #f0f0f0;
  margin-top: 0px !important;
  padding: 10px 16px !important;
}

.ant-modal-confirm-confirm .ant-modal-footer {
  border-top: none !important;
  padding: 0 !important;
}

/* Конец лечения модалок */

.ant-modal-confirm-confirm .ant-modal-confirm-btns {
  margin-top: 30px;
}

/* Возвращаем адекватный размер иконки "v" в дропдауне */
.ant-dropdown-trigger .anticon-down {
  font-size: 10px !important;
}

/* Убираем чрезмерно темное подчеркивание табов */
.ant-tabs-card .ant-tabs-tab {
  border: 1px solid #f0f0f0 !important;
}

/* Убираем уродливую синюю рамку вокруг сфокусированного элемента */
div *:focus {
  outline: none;
}

.ant-table-cell-scrollbar {
  background: none !important;
}

/**
 * Конец специальной зоны костылей под antd v5
*/